.page-title {
    position: relative;
    padding-bottom: 15px;
}

.page-title::after {
    content: '';
    border-bottom: 5px solid #1976d2;
    position: absolute;
    left: 0;
    bottom: 0;
    display: inline-block;
    width: 50%;
}

.exit-modal {
    position: absolute!important;
    top: 10px;
    right: 10px;
}