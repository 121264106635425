html,
body {
    background-color: #eee;
    margin: 0;
    padding: 0;
}

.login-view {
    position: relative;
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    padding: 0 10px;
    z-index: 2;
}

.video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: rgba(238,238,238, 0.8);*/
    background: rgba(0,0,0, 0.8);
    z-index: 1;
}

.login-logo {
    pointer-events: none;
    width: 350px;
}

.dashboard-logo {
    pointer-events: none;
    width: 180px;
    margin: 25px auto;
}

@media (prefers-reduced-motion: no-preference) {
    .login-logo, .dashboard-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.login-container {
    max-width: 400px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    padding: 40px 0;
    margin-top: 25px;
    background: rgba(0,0,0, 0.5);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
}

.login-button {
    width: 80%;
}

.run-button button {
    transition: all ease-in-out 0.2s!important;
    background: #888!important;
}

.run-button:hover button {
    opacity: 0!important;
    cursor: default;
    transform: scale(1%);
}

.container-login-button {
    width: 100%;
    padding: 0;
    margin: 0;
}

.login-input, .password-input {
    width: 80%;
    margin-bottom: 15px!important;
}

.password-strength {
    width: 80%;
    margin-top: -15px;
    margin-bottom: 15px;
}

/* Zmiana koloru bordera */
.login-view .MuiOutlinedInput-root {
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #555; /* Kolor bordera w stanie aktywnym (focused) */
        transition: .4s;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: #555; /* Kolor domyślnego bordera */
        transition: .4s;
    }
}

/* Zmiana koloru tekstu */
.login-view .MuiInputLabel-root {
    color: white !important; /* Kolor tekstu labela */
}

/* Zmiana koloru tekstu w stanie aktywnym (focused) */
.login-view .MuiInputLabel-root.Mui-focused {
    color: white !important;
}

/* Zmiana koloru wprowadzanych znaków */
.login-view .MuiInputBase-input {
    color: white !important; /* Kolor wprowadzanych znaków */
}

/* Zmiana koloru placeholdera */
.login-view .MuiInputLabel-outlined {
    color: #555 !important; /* Kolor placeholdera dla OutlinedInput */
}

/* Zmiana koloru ikony do wyświetlania/chowania hasła */
.login-view .MuiIconButton-root {
    color: white !important; /* Kolor ikony */
}

/* Zmiana koloru bordera w stanie hover */
.login-view .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: white;
}

/* Zmiana koloru bordera w stanie hover */
.login-view .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: white !important;
}

/* Zmiana koloru tekstu w stanie hover */
.login-view .MuiInputLabel-root.Mui-focused:hover {
    color: white !important;
}

/* Zmiana koloru ikony do wyświetlania/chowania hasła w stanie hover */
.login-view .MuiIconButton-root:hover {
    color: white !important;
}